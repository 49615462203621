<template>
  <div id="faq-page-wrapper">
    <vs-popup classContent="popup-example" :title="$t('add_new')" :active.sync="showModal">
      <div class="vx-col w-full my-2">
        <vs-input v-validate="'required'" data-vv-as="field" class="w-full" name="question_tm" :placeholder="$t('questionTurkmen')" v-model="newQuestion.tm" />
        <span class="text-danger text-sm" v-show="errors.has('question_tm')">{{ errors.first('question_tm') }}</span>
      </div>
      <div class="vx-col w-full my-2">
        <vs-input v-validate="'required'" data-vv-as="field" class="w-full" name="question_en" :placeholder="$t('questionEnglish')" v-model="newQuestion.en" />
        <span class="text-danger text-sm" v-show="errors.has('question_en')">{{ errors.first('question_en') }}</span>
      </div>
      <div class="vx-col w-full my-2">
        <vs-input v-validate="'required'" data-vv-as="field" class="w-full" name="question_ru" :placeholder="$t('questionRussian')" v-model="newQuestion.ru" />
        <span class="text-danger text-sm" v-show="errors.has('question_ru')">{{ errors.first('question_ru') }}</span>
      </div>
      <div class="vx-col w-full my-2">
        <vs-textarea height="85px" v-validate="'required'" class="mb-0 w-full" name="answer_tm" :placeholder="$t('answerTurkmen')" v-model="newAnswer.tm" />
        <span class="text-danger text-sm" v-show="errors.has('answer_tm')">{{ errors.first('answer_tm') }}</span>
      </div>
      <div class="vx-col w-full my-2">
        <vs-textarea height="85px" v-validate="'required'" class="mb-0 w-full" name="answer_en" :placeholder="$t('answerEnglish')" v-model="newAnswer.en" />
        <span class="text-danger text-sm" v-show="errors.has('answer_en')">{{ errors.first('answer_en') }}</span>
      </div>
      <div class="vx-col w-full my-2">
        <vs-textarea height="85px" v-validate="'required'" class="mb-0 w-full" name="answer_ru" :placeholder="$t('answerRussian')" v-model="newAnswer.ru" />
        <span class="text-danger text-sm" v-show="errors.has('answer_ru')">{{ errors.first('answer_ru') }}</span>
      </div>
      <div class="w-full flex mt-4">
        <vs-button type="filled" @click.prevent="submitForm" class="block">{{$t('submit')}}</vs-button>
        <vs-button type="border" color="warning" @click.prevent="cancelForm" class="ml-auto block">{{$t('cancel')}}</vs-button>
      </div>
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <div v-show="!isLoading">
      <vx-card v-show="questions.length">
        <vs-table hoverFlat ref="table" :data="data" :key="key" search pagination :max-items="itemsPerPage">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <div class="flex flex-wrap-reverse items-center">
              <vs-button type="border" class="mb-4 p-3" icon-pack="feather" icon="icon-plus" @click.prevent="addNewFaq">{{ $t('add_new') }}</vs-button>
            </div>
            <vs-dropdown :key="key" vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ questions.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : questions.length }} of {{ queriedItems }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage=4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <template slot="thead">
            <vs-th>ID</vs-th>
            <vs-th>{{ $t('questionTurkmen') }}</vs-th>
            <vs-th>{{ $t('questionEnglish') }}</vs-th>
            <vs-th>{{ $t('questionRussian') }}</vs-th>
            <vs-th>{{ $t('props.createdAt') }}</vs-th>
          </template>


          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="border-0 border-t border-solid border-grey-light pt-3">

              <vs-td :data="data[indextr].id">
                <span class="font-medium">#{{ data[indextr].id }}</span>
              </vs-td>

              <vs-td :data="data[indextr].title_tm">
                {{ data[indextr].title_tm }}
              </vs-td>
              
              <vs-td :data="data[indextr].title_ru">
                {{ data[indextr].title_ru }}
              </vs-td>

              <vs-td :data="data[indextr].title_en">
                {{ data[indextr].title_en }}
              </vs-td>

              <vs-td :data="data[indextr].createdAt">
                {{ data[indextr].createdAt }}
              </vs-td>

              <template class="expand-order bg-primary" slot="expand">
                <div class="con-expand-orders w-full pl-3">
                  <div class="con-btns-user flex items-center justify-between">
                    <div class="con-userx flex items-center justify-start">
                      <h3>{{ data[indextr].title_en }}</h3><span class="pt-1"> #{{ data[indextr].id }}</span> 
                    </div>
                    <div class="flex">
                      <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary current-stroke" class="ml-2" @click.stop="editData(tr.id)" />
                      <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger current-stroke" class="ml-2" @click.stop="deleteData(tr.uuid)" />
                    </div>
                  </div>
                  <div class="items-container border rounded-lg overflow-hidden border-grey-light border-solid mt-3">
                    <div class="vx-row w-full ml-0 border-b border-0 border-solid border-grey-light pt-2 px-3 pb-1" style="background: #ededed;">
                      <div class="w-1/3 font-medium">Turkmen</div>
                      <div class="w-1/3 font-medium">Russian</div>
                      <div class="w-1/3 font-medium">English</div>
                    </div>
                    <div class="vx-row ml-0 p-3">
                      <div class="w-1/3">{{ tr.text_tm }}</div>
                      <div class="w-1/3">{{ tr.text_ru }}</div>
                      <div class="w-1/3">{{ tr.text_en }}pcs</div>
                    </div>
                  </div>
                </div>
              </template>
            </vs-tr>
          </template>

        </vs-table>
      </vx-card>
      <div v-if="!questions.length" class="vx-row buttons-container">
        <vs-button type="border" class="mx-4 p-3" icon-pack="feather" icon="icon-plus" @click.prevent="isAdding = true">{{ $t('add_new') }}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      key: 1,
      itemsPerPage: 10,
      questions: [],
      isAdding: false,
      isEditing: null,
      newQuestion: {},
      newAnswer: {},
      isLoading: true
    }
  },
  computed: {
    data ()         { return this.questions },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.questions.length },
    currentPage ()  { return this.isLoading ? 0 : this.$refs.table.currentx },
    showModal: {
      get () { return this.isAdding || !!this.isEditing },
      set () {
        this.isAdding = false
        this.isEditing = null
      }
    }
  },
  methods: {
    addNewFaq () {
      this.newQuestion = {}
      this.newAnswer = {}
      this.isAdding = true
    },
    editData (id) {
      const editObj = this.questions.find(el => el.id === id)
      this.newQuestion = {
        tm: editObj.title_tm,
        en: editObj.title_en,
        ru: editObj.title_ru
      }
      this.newAnswer = {
        tm: editObj.text_tm,
        en: editObj.text_en,
        ru: editObj.text_ru
      }
      this.isEditing = editObj.uuid
    },
    deleteData (uuid) {
      this.$http.delete(`/faqs/delete/${uuid}`).then(() => {
        this.notify('Success', 'FAQ deleted successfully!', 'icon-check-circle', 'primary')
        this.fetchData()
      }).catch(() => {
        this.notify('Error', 'Something went wrong! Please try again later.', 'icon-alert-circle', 'danger')
      })
    },
    async fetchData () {
      await this.$http.get('/faqs').then(res => {
        this.questions = res.data
        this.key++
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.notify('Error', err.message, 'icon-alert-circle', 'danger')
      })
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const reqData = {
            title_tm: this.newQuestion.tm,
            title_ru: this.newQuestion.ru,
            title_en: this.newQuestion.en,
            text_tm:  this.newAnswer.tm,
            text_ru:  this.newAnswer.ru,
            text_en:  this.newAnswer.en
          }
          if (this.isAdding) {
            this.$http.post('/faqs/add', reqData)
              .then(() => {
                this.notify('Success', 'FAQ created successfully!', 'icon-check-circle', 'primary')
                this.isAdding = false
                this.fetchData()
              })
          } else if (this.isEditing) {
            this.$http.patch(`/faqs/edit/${this.isEditing}`, reqData)
              .then(() => {
                this.notify('Success', 'FAQ updated successfully!', 'icon-check-circle', 'primary')
                this.isEditing = null
                this.fetchData()
              })
          }
        } else {
          this.notify('Error', 'Fill all required fields first!', 'icon-alert-circle', 'danger')
        }
      })
    },
    cancelForm () {
      this.isEditing = null
      this.isAdding = false
      this.newQuestion = {}
      this.newAnswer = {}
    },
    notify (title, text, icon, color) {
      this.$vs.notify({ title, text, iconPack: 'feather', icon, color })
    }
  },
  async created () {
    await this.fetchData()
  }
}
</script>

<style lang="scss">
#faq-page-wrapper {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
}
</style>